.block-default {
    @media (min-width: $smartphone) {
        --font-size-breadcrumb: var(--font-size-base);
        --font-size-title: #{rem(75)};
        --padding-top: var(--header-height);
        --padding-bottom: var(--padding-s);
        --padding-left: var(--header-size);
        --padding-right: var(--padding-xxs);
        --top-sticky: 0;
        --header-sticky-height: var(--header-height);
        --margin-sticky-header: 0;
    }
    
    @media (max-width: $smartphone) {
        --font-size-breadcrumb: var(--font-size-s);
        --font-size-title: #{rem(24)};
        --padding-top: var(--header-height);
        --padding-bottom: var(--padding-xxs);
        --padding-left: var(--padding-xxs);
        --padding-right: var(--padding-xxs);
        --top-sticky: var(--header-height);
        --header-sticky-height: #{rem(24)};
        --margin-sticky-header: 0 0 var(--padding-s);
       
    }

    &:not(.block-hero) {
        &:first-child {
            --padding-top: 0;
        }
    }

    position: relative;
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);

    > header {
        counter-increment: counter_section;

        position: sticky;
        top: var(--top-sticky);
        @include z-index(2);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: var(--header-sticky-height);
        margin: var(--margin-sticky-header);
        
        user-select: none;

        > .title {
            order: 2;
            @include pildora();
            font-size: var(--font-size-title);
        }

        > .breadcrumb {
            order: 1;
            @include font-serif();
            font-size: var(--font-size-breadcrumb);
            text-transform: uppercase;
            padding: 0;
            background-color: var(--primary-bg);

            > span::before {
                content: "0" counter(counter_section) ".";
            }
        }
    }
}
