// #Footer {
//     --font-size: var(--font-size-small);
//     padding: var(--padding-s) var(--padding-xs) var(--padding-xl);
// }

// #Footer > nav {
//     display: flex;
//     align-items: flex-end;

//     a {
//         @include font-sans(1.2);
//         font-size: var(--font-size);
//         color: inherit;
//         margin: 0 1em;
//     }
// }

// @media (max-width: $smartphone) {
//   #Footer {
//       --font-size: rem(12px);
//   }
// }


@use "sass:math";

#Footer {
    --font-size: var(--font-size-s);
    
   
    --padding-bottom: var(--padding-xxs);
    --gap: var(--padding-xs);
   
    @media (min-width: $smartphone) {
        --padding-top: var(--padding-xl);
        --font-size-copy: var(--font-size-m);
        --size-logo-1: #{rem(20)};
        --size-logo-2: #{rem(24)};
        --size-logo-cuchillo: #{rem(48)};
    }

    @media (max-width: $smartphone) {
        --padding-top: 0;
        --font-size-copy: var(--font-size-l);
        --size-logo-1: #{rem(20)};
        --size-logo-2: #{rem(24)};
        --size-logo-cuchillo: #{rem(52)};
    }
}

#Footer .top {
    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: var(--padding-m);
    }

    @media (max-width: $smartphone) {
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        .copy {
            order: 3;
        }

        .address {
            order: 1;
        }

        .contact {
            order: 2;
        }

        .copy, .address, .contact {
            width: 100%;
            text-align: center;  
            margin: 1em 0;
        }
    }

    color: var(--primary-color);
    
    @include font-serif();
    color: var(--primary-color);
    text-transform: uppercase;

    a,
    *,
    a * {
        &::-moz-selection { /* Code for Firefox */
            color: var(--primary-bg);
            background: var(--primary-color);
        }

        &::selection {
            color: var(--primary-bg);
            background: var(--primary-color);
        }
    }

    .copy {
        font-size: var(--font-size-copy);
    }

    .address {
        font-size: var(--font-size);

        @media (min-width: $smartphone) {
            padding-right: var(--padding-xl);
        }

        a {
            @include basic-a();
            text-decoration: underline;
            color: var(--primary-color);
            
        }
    }

    .contact {
        font-size: var(--font-size);

        a {
            @include basic-a();
            color: var(--primary-color);
            display: block;

            &::before {
                content: '☞';
            }
        }
    }
}

#Footer > .logo {
    @media (min-width: $smartphone) {
        width: calc(100vw - var(--padding-left) * .5);
        margin-left: calc(var(--padding-left) * -.5);
    }
    @media (max-width: $smartphone) { 
        width: 100%;
    }
}

#Footer .bottom {
    @include font-serif();
    font-size: var(--font-size-xs);
    display: flex;
    
    flex-wrap: wrap;
    margin-top: var(--padding-m);

    @media (min-width: $smartphone) {
        align-items: flex-end;
        justify-content: space-between;
    }

    @media (max-width: $smartphone) {
        align-items: center;
        justify-content: center;
        gap: var(--padding-m);

        .legales {
            text-align: center;
            order: 2;
        }

        .kitdigital {
            order: 1;
        }
    }
    
    a, button {
        @include basic-a();
        color: var(--primary-color);
        padding: 0;
        background-color: transparent;
        text-decoration: underline;
    }

    ul {
        flex: 1 0 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: $smartphone) {
            margin-bottom: -4px;
            margin-right: -4px;
        }
    }

    .logo-cuchillo {
        margin-right: 1.4em;

        > a {
            flex: 0 0 auto;
            position: relative;
            display: flex;
            align-items: flex-end;
            gap: .41em;
            color: var(--primary-color);
            font-family: sans-serif;
            font-size: #{rem(8)};
            text-decoration: none;

            span {
                display: block;
                position: relative;
                top: .2em;
            }

            svg {
                position: relative;
                fill: currentColor;
                width: var(--size-logo-cuchillo);
                height: auto;
                top: -.1em;

                @media (max-width: $smartphone) {
                    top: -.1em;
                }
            }

            sup {
                position: absolute;
                top:.45em;
                right: -.8em;
            }
        }
    }

    .logo-kd01 {
        > svg {
            display: block;
            height: var(--size-logo-1);
            width: auto;
            fill: currentColor;
        }
    }

    .logo-kd02 {
        > svg {
            display: block;
            height: var(--size-logo-2);
            width: auto;
            fill: currentColor;
        }
    }
}