:root {
    --white: #ffffff;
    --black: rgba(6,8,4,1);
    --grey: #D1D1D1;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}