@use "sass:math";

#Header {
    --color: var(--primary-color);
    --bg: transparent;

    @media (min-width: $smartphone) {
        --width: var(--header-width);
        --height: 100vh;
        --logo-width: 100%;
        --padding-v: var(--padding-xxs);
        --padding-h: var(--padding-xxs);
        --font-size: var(--font-size-base);
        --font-size-rrss: var(--font-size-s);
    }
    
    @media (max-width: $smartphone) {
        --width: 100%;
        --height: auto;
        --logo-width: #{rem(110)};
        --padding-v: var(--padding-xxs);
        --padding-h: var(--padding-xxs);
        --font-size: var(--font-size-s);
        --font-size-rrss: var(--font-size-s);
       
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);

    left: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    align-items: flex-start;

    color: var(--color);
    background-color: var(--bg);

    @media (min-width: $smartphone) {
        flex-direction: column;
        justify-content: space-between;
    }

    @media (max-width: $smartphone) {
        flex-direction: row;
        justify-content: space-between;
    }

    .logo {
        width: var(--logo-width);
        height: auto;
        background-color: transparent;

        svg {
            width: 100%;
            height: auto;
            fill: var(--color);
        }
    }

    a {
        @include basic-a();
        @include font-serif();
        color: var(--color);
        text-transform: uppercase;
        background-color: var(--primary-bg);
        user-select: none;
    }

    nav {
        position: relative;

        @media (max-width: $smartphone) {
            flex: 1 0 auto;

            ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding-top: .2em;

                li {
                    text-align: left;
                }

                a {
                    padding: .2em 0;
                }
            }
        }
    }

    ul {
        padding: 0 0 0 var(--padding-xs);

        li {
            display: block;
        }
    }

    @media (max-width: $smartphone) {
       > ul { display: none; } //RRSS
       
    }
}

.body-legal #Header nav {
    display: none;
}

.link-header {
    font-size: var(--font-size);
    &[aria-current="page"]::before {
        content: '↳';
        margin-right: .15em;
    }

    &:hover::before {
        content: '↳';
        margin-right: .15em;
    }
}

.link-rrss {
    position: relative;
    font-size: var(--font-size-rrss);
    text-decoration: underline;

    > span {
        text-decoration: underline;
    }

    &::before {
        position: absolute;
        top:0;
        left:-1.3em;
        content: '☞';
    }
}

@media (max-width: $smartphone) {
  #Header {
      --font-size: #{math.div(16px, 16px) * 1rem};

      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;

      > .title {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
      }

      > .date {
          grid-column: 1 / 2;
          grid-row: 2 / 3;

          align-self: flex-end;
      }
  }

  #Header > nav {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      grid-template-columns: repeat(1, 1fr);

      text-align: right;

      a:not(:last-of-type) {
          margin-bottom: .4em;
      }
  }
}
