//↗ → ← ↖

@mixin pildora() {
    --rotate: -9deg;
    position: relative;
    @include font-sans();
    color: var(--primary-bg);
    line-height:.85;
    text-transform: uppercase;
    padding: 0 var(--padding-xs) 0 var(--padding-xxxs);
    
    &::before {
        content: '';
        position: absolute;
        @include z-index(-1);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--primary-color);
        border-radius: .38em;
        transform: rotate(var(--rotate));
        transform-origin: 50% 50%;
    }
}

.pildora {
    @include pildora();
}
