.block-legal {
    --font-size-title: var(--font-size-xxl);
    --font-size-subtitle: var(--font-size-m);
    --font-size-third: var(--font-size-base);
    --font-size: var(--font-size-s);
        
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-l);
        --font-size-subtitle: var(--font-size-m);
        --font-size-third: var(--font-size-base);
        --font-size: var(--font-size-s);
    }

    --padding-top: 0;
}


.block-legal {
    font-size: var(--font-size);
    text-transform: uppercase;
    @include font-serif();
    
    h1 {
        @include font-sans(.85);
        font-size: var(--font-size-title);
        margin-bottom: .2em;
    }
    
    h2 {
        @include font-sans(.85);
        font-size: var(--font-size-subtitle);
        margin-bottom: .8em;
    }

    h3 {
        @include font-sans(.85);
        font-size: var(--font-size-third);
        margin-bottom: .8em;
    }

    p,
    ul {
        margin-bottom: .8em;
        max-width: rem(850);
        
        + h2 {
            margin-top: 1.6em;
        }
        
        + h3 {
            margin-top: 1.6em;
        }
    }

    ul {
        padding-left: var(--padding-s);

        li {
            list-style: initial;
        }
    }

    a {
        color: var(--primary-color);
        word-break: break-word;
    }

    @media (max-width: $smartphone) {
       
    }
}
