.block-info-image {
    --color: var(--primary-color);
    --bg: var(--primary-bg);

    @media (min-width: $smartphone) {
        --gap: var(--padding-s);
        --font-size-title-info: var(--font-size-xxl);
        --font-size: var(--font-size-s);
        --card-radius: #{rem(22)};
        --flex-direction: row;
    }

    @media (max-width: $smartphone) {
        --gap: var(--padding-xs);
        --font-size-title-info: var(--font-size-xl);
        --font-size: var(--font-size-xs);
        --card-radius: #{rem(22)};
        --flex-direction: column;
    }
}

@media (min-width: $smartphone) {
    .block-info-image + .block-info-image {
        padding-top: var(--padding-bottom);
    }
}

.block-info-image {
    display: flex;
    flex-direction: var(--flex-direction);
    justify-content: center;
    align-items: center;
    gap: var(--gap);
    padding-right: var(--padding-left);

    .media {
        @media (min-width: $smartphone) {
            flex: 1 0 40%;
        }

        @media (max-width: $smartphone) {
            width: 100%;
        }
    }

    figure.media-holder {
        border-radius: var(--card-radius);
        overflow: hidden;

        img, video {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 140%;
            object-fit: cover;
        }
    }

    .info {
        @media (min-width: $smartphone) {
            flex: 1 0 40%;
        }

        @media (max-width: $smartphone) {
            width: 100%;
        }

        > .title {
            @include font-sans(.85);
            font-size: var(--font-size-title-info);
            color: var(--color);
            text-transform: uppercase;
            user-select: none;
        }

        > .summary {
            @include font-serif();
            font-size: var(--font-size);
            color: var(--color);
            text-transform: uppercase;

            * {
                &::-moz-selection { /* Code for Firefox */
                    color: var(--primary-bg);
                    background: var(--primary-color);
                }
        
                &::selection {
                    color: var(--primary-bg);
                    background: var(--primary-color);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        &:nth-child(odd) {
            .media { order: 1; }
            .info { order: 2; }
        }

        &:nth-child(even) {
            .media { order: 2; }
            .info { order: 1; }
        }
    }

    @media (max-width: $smartphone) {
        .media { order: 2; }
        .info { order: 1; }
    }
}