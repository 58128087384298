@use "sass:math";

#Scrollbar {
    --width: #{rem(3)};
    --height: 96%;

    position: absolute;
    @include z-index($z-index-scrollbar);

    width: var(--width);
    height: var(--height);
    top: 2%;
    left: calc(var(--width) * -.5);
    overflow: hidden;
    
    .track,
    .thumb2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .track {
        background-color: transparent;
    }

    .thumb2 {
        width: 100%;
        height: calc(100% * var(--progress));
        transform-origin: 0 0;
        background-color: var(--primary-color);
        //transform: scale(1,0);
        border-radius: var(--width);
    }

    @include isTouch() {
        display: none;
    }
}
