.block-about {
   @media (min-width: $smartphone) {
        --width-card: #{rem(900)};
        --height-card: #{rem(525)};
        --radius: #{rem(24)};

        --height-header: #{rem(64)};

        --padding-logo: #{rem(20)};
        --padding: 0;//var(--padding-logo) 0 var(--padding-logo) var(--padding-logo);
        --padding-card: 0 #{rem(25)} #{rem(25)};
        --padding-header: var(--padding-xxs) var(--padding-xxs) 0;
        --margin-header: #{rem(50)};
        --padding-text: 0 var(--padding-xl);

        --font-size: var(--font-size-base);
        --padding-bottom:25vw;
    }

    @media (max-width: $smartphone) {
        --width-card: 100%;
        --height-card: 100vw;
        --radius: #{rem(24)};

        --height-header: #{rem(32)};

        --padding-logo: #{rem(20)};
        --padding: 0;//var(--padding-logo) 0 var(--padding-logo) var(--padding-logo);
        --padding-card: 0 #{rem(25)} #{rem(25)};
        --padding-header: var(--padding-xxs) var(--padding-xxs) 0;
        --margin-header: #{rem(40)};
        --padding-text: 0;

        --font-size: var(--font-size-xs); 
    }
}

@media (max-width: $smartphone) {
    .block-about li {
        --index: 0;
        position: sticky;
        top: calc(var(--padding-top) + var(--padding-m) * var(--index))
    }
}


.block-about .card {
    position: relative;
    background-color: var(--grey);
    width: var(--width-card);
    height: var(--height-card);
    padding: var(--padding-card);
    border-radius: var(--radius);
    border: 1px solid var(--primary-bg);

    @include font-serif();
    font-size: var(--font-size);
    text-transform: uppercase;

    > .text {
        padding: var(--padding-text);

        * {
            &::-moz-selection { /* Code for Firefox */
                color: var(--primary-color);
                background: var(--primary-bg);
            }
    
            &::selection {
                color: var(--primary-color);
                background: var(--primary-bg);
            }
        }
    }

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: var(--height-header);
        padding: var(--padding-header);
        margin-bottom: var(--margin-header);
        user-select: none;

        svg {
            height: 100%;
            width: auto;
        }
    }

    > svg {
        position: absolute;
        left: var(--padding-logo);
        bottom:  var(--padding-logo);
        width: calc(100% - var(--padding-logo) * 2);
        fill: transparent;
        stroke: var(--primary-bg);
        stroke-width: 1px;
    }
}

.block-about {
    ul {
        transform-style: preserve-3d;
        position: relative;
        @include font-sans(.85);
        font-size: var(--font-size);
        text-transform: uppercase;
        padding: var(--padding);
        perspective: rem(2000);
        width: var(--width-card);

        @media (min-width: $smartphone) {
            margin-left: var(--padding-l);
        }

        li {
            width: 100%;
            transform-origin: center center;
        }
    }
}
