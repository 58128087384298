.block-who {
   @media (min-width: $smartphone) {
        --font-size: var(--font-size-xl);
        --padding: var(--padding-s) 0 var(--padding-s) var(--padding-l);
        --padding-bg: var(--padding-s) #{rem(130)};
        margin-top: 60vh;
    }

    @media (max-width: $smartphone) {
        --font-size: 10vw;
        --padding: 0 0 125vw;
        --padding-bg: 0 0;
        padding-top: 0;
        margin-top: 0;
    }
}

.block-who {
    position: relative;

    > .bg {
        @media (min-width: $smartphone) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
        }
    
        @media (max-width: $smartphone) {
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
        }

        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--padding-bg);
        background-color: var(--primary-bg);
        overflow: hidden;
        
        > .content {
            position: relative;
            width: 100%;

            &:after {
                content: '';
                position: absolute;
                top: -1%;
                left: -1%;
                width: 102%;
                height: 102%;
            }
                        
            > svg {
                position: relative;
                display: block;
                width: 100%;
                height: auto;
                fill: var(--primary-bg);
            }

            > .video {
                position: absolute;
                top: .2%;
                left: .2%;
                width: 99.6%;
                height: 99.6%;
                overflow: hidden;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center; 
                }
            }
        }
    }

    ul {
        position: relative;
        @include font-sans(.85);
        font-size: var(--font-size);
        text-transform: uppercase;
        padding: var(--padding);
        overflow: hidden;

        li {
            transform-origin: -25% 50%;
            user-select: none;

            @media (max-width: $smartphone) {
                margin: .3em 0;
            }
        }
    }
}
