.block-hero {
    --padding-top: var(--header-height);
    
    @media (min-width: $smartphone) {
        --font-size-header: var(--font-size-s);
        --offset-line1: #{rem(-8)};
        --offset-line2: #{rem(-60)};
        --height: auto;
        --padding-bottom: var(--padding-s);
    }

    @media (max-width: $smartphone) {
        --font-size-header: var(--font-size-xs);
        --offset-line1: #{rem(-4)};
        --offset-line2: #{rem(-20)};
        --height: calc(var(--vh, 1vh) * 100);
        --padding-bottom: var(--padding-xxs);
    }
}


.block-hero {
    width: 100%;

    @media (min-width: $smartphone) {
        > .header {
            position: absolute;
            top: 0;
            left: 0;
            height: var(--header-height);
            padding: var(--padding-xxs) var(--padding-xxs) var(--padding-xxs) var(--header-size);
        }
    }

    @media (max-width: $smartphone) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: var(--height);

        > .header {
            order: 2;
            position: relative;
            padding: 0;
        }

        > .title {
            order: 1;
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    > .header {
        display: flex;
        align-items: center;
        width: 100%;

        @include font-serif();
        font-size: var(--font-size-header);
        text-transform: uppercase;
        
        p {
            background-color: var(--primary-bg);

            &::-moz-selection { /* Code for Firefox */
                color: var(--primary-bg);
                background: var(--primary-color);
            }
    
            &::selection {
                color: var(--primary-bg);
                background: var(--primary-color);
            }
        }
    }

    > .title {
        /*@include font-sans(.75);
        font-size: var(--font-size);
        text-align: center;

        strong {
            display: block;
            @include pildora();
        }*/

        > span {
            display: block;
            width: 100%;
            overflow: hidden;

            svg {
                width: 100%;
                height: auto;
                fill: var(--primary-color);
            }

            &.line1, 
            &.line2 {
                margin-top: var(--offset-line1);
            }

            &.line3 {
                margin-top: var(--offset-line2);
            }
        }
    }
}
