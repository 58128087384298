.block-works {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    

    @media (min-width: $smartphone) {
        --font-size-title-category: var(--font-size-xxl);
        --font-size-card: var(--font-size-xs);
        --card-width: #{rem(750)};
        --card-width-image: #{rem(480)};
        --card-width-info: calc(var(--card-width) - var(--card-width-image));
        --card-height: #{rem(480)};
        --card-radius: #{rem(22)};
        --gap: var(--padding-xs);
    }

    @media (max-width: $smartphone) {
        --font-size-title-category: 20vw;
        --font-size-card: var(--font-size-xs);
        --card-width: 90vw;
        --card-height: 70vh;
        --card-radius: #{rem(22)};
        --gap: #{rem(4)};
    }
}

.block-works {
    color: var(--color);
    padding-left: 0;
    padding-right: 0;

    > header {
        padding-left: var(--padding-left);
        padding-right: var(--padding-right);
    }
}

.block-works__group {
    @media (min-width: $smartphone) {
        padding-bottom: var(--padding-xl);
    }
    @media (max-width: $smartphone) {
        padding-bottom: var(--padding-m);
    }

    > .title {
        padding-left: var(--padding-left);
        pointer-events: none;
        user-select: none;

        span {
            --offset: 0;
            display: block;
            @include font-sans(.85);
            font-size: var(--font-size-title-category);
            @media (min-width: $smartphone) {
                padding-bottom: calc(.5em * var(--offset));
            }
        }
        
        text-transform: uppercase;    
    }

    > ul {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: var(--gap);

        @media (min-width: $smartphone) {
            padding-left: var(--padding-left);
            padding-right: var(--padding-right);
        }

        @media (max-width: $smartphone) {
            padding-left: var(--padding-xxxs);
            padding-right: var(--padding-xxxs);
        }

        cursor: grab;    
        &:active {
            cursor: grabbing;
        }    
    }
}

.block-works__card {
    width: var(--card-width);
    height: var(--card-height);
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $smartphone) {
        border-top-right-radius: var(--card-radius);
        border-bottom-right-radius: var(--card-radius);
    }

    @media (max-width: $smartphone) {
        border-bottom-left-radius: var(--card-radius);
        border-bottom-right-radius: var(--card-radius);
    }

    overflow: hidden;

    @include font-serif();
    font-size: var(--font-size-card);

    .media {
        position: relative;
        flex: 0 0 var(--card-width-image);
        width: var(--card-width-image);
        overflow: hidden;
        padding: var(--padding-xxs);
        
        img, video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;

            object-fit: cover;
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }

        .name {
            position: relative;
            display: block;
            position: relative;
            mix-blend-mode: difference;
            color: white;
        }
    }

    .info {
        flex: 0 0 var(--card-width-info);
        width: var(--card-width-info);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--padding-xxs);

        color: var(--white);
        background-color: var(--black);
        text-transform: uppercase;

        user-select: none;

        .summary p:first-child::before {
            --height: .6em;
            --width: calc(.5em * 2.2);

            content:'';
            display: inline-block;
            width: var(--width);
            height: var(--height);
            border: #{rem(1)} solid currentColor;
            border-radius: 50%;
            margin: 0 .4em;
        }
    }

    @media (max-width: $smartphone) {
        flex-direction: column;

        .media {
            flex: 0 0 auto;
            width: 100%;
        }

        .info {
            flex: 1 0 auto;
            width: 100%;
        }

        .media {
            height: calc(var(--card-width) * .6);
        }
    }
}