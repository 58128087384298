.block-services {
   @media (min-width: $smartphone) {
        --font-size-button: var(--font-size-l);
        --font-size: var(--font-size-base);
        --padding: var(--padding-s) 0 var(--padding-s) 0;
        --cross-size: #{rem(70)}; 
        --cross-size-position: var(--padding-l);
        --cross-size-padding: var(--padding-xxs);
        --max-width-content: calc(#{rem(680)} + var(--cross-size));
        --padding-content: var(--padding-s) var(--cross-size) var(--padding-m) 0;
        --border: #{rem(2)};

        --padding-button: var(--padding-m) 0;
        --padding-ul: var(--padding-s) 0;
    }

    @media (max-width: $smartphone) {
        --font-size-button: var(--font-size-base);
        --font-size: var(--font-size-s);
        --padding: var(--padding-xs) 0;
        --cross-size: #{rem(30)}; 
        --cross-size-position: var(--padding-xxs);
        --cross-size-padding: var(--padding-xxs);
        --max-width-content: 100%;
        --padding-content: var(--padding-xs) 0;
        --border: #{rem(2)};

        --padding-button: var(--padding-s) 0;
        --padding-ul: var(--padding-xs) 0;
    }
}


.block-services {
    ul {
        position: relative;
        @include z-index(0);
        @include font-serif();
        padding: var(--padding-ul);

        @media (max-width: $smartphone) {
            overflow: hidden;
        }
                
        li {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -100vw;
                width: 200vw;
                border-top: var(--border) solid currentColor;
            }

            &:last-of-type {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -100vw;
                    width: 200vw;
                    border-top: var(--border) solid currentColor;
                }
            }

            article {
                --rotation: 0deg;
                --rotation-aux: 90deg;

                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;

                &[aria-expanded="true"] {
                    --rotation: 135deg;
                }
            
                &[aria-expanded="false"] {
                    --rotation: 0deg;
                }
            }
            
            button {
                position: relative;
                @include basic-a();
                width: 100%;
                flex: 1 0 100%;
                font-size: var(--font-size-button);
                background-color: transparent;
                padding: var(--padding);
                text-align: left;

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    right: var(--cross-size-position);
                    top: calc(50% - var(--border) * .5);
                    width: calc(var(--cross-size) - var(--cross-size-padding));
                    border-top: var(--border) solid currentColor;
                    transform-origin: 50% 50%;
                    transition: transform;
                    transition-timing-function: var(--ease);
                    transition-duration: var(--duration);
                }

                &::after {
                    transform: rotate(calc(var(--rotation) + var(--rotation-aux)));
                }

                &::before {
                    transform: rotate(var(--rotation));
                }
            }

            div {
                font-size: var(--font-size);
                max-width: var(--max-width-content);
                padding: var(--padding-content);
                
                * {
                    &::-moz-selection { /* Code for Firefox */
                        color: var(--primary-bg);
                        background: var(--primary-color);
                    }
            
                    &::selection {
                        color: var(--primary-bg);
                        background: var(--primary-color);
                    }
                }
            }
        }
    }
}
