.block-contact {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
   
    @media (min-width: $smartphone) {
        --gap: var(--padding-s);
        --font-size-title-info: var(--font-size-xxl);
        --font-size: var(--font-size-s);
        --font-size-email: var(--font-size-l);
    }

    @media (max-width: $smartphone) {
        --gap: var(--padding-s);
        --font-size-title-info: 25vw;
        --font-size: var(--font-size-xs);
        --font-size-email: var(--font-size-l);
    }
}


.block-contact {
    .content {
        display: flex;
        flex-wrap: wrap;
        column-gap: var(--gap);

        > .title {
            flex: 1 0 100%;
            @include font-sans(.85);
            font-size: var(--font-size-title-info);
            color: var(--color);
            text-transform: uppercase;
            margin-top: .2em;
            user-select: none;
        }

        > .summary {
            flex: 1 0 40%;
            @include font-serif();
            font-size: var(--font-size);
            color: var(--color);
            text-transform: uppercase;

            * {
                &::-moz-selection { /* Code for Firefox */
                    color: var(--primary-bg);
                    background: var(--primary-color);
                }
        
                &::selection {
                    color: var(--primary-bg);
                    background: var(--primary-color);
                }
            }
        }

        > .info {
            flex: 1 0 40%;
            @include font-serif();
            font-size: var(--font-size-email);
            color: var(--color);
            text-transform: uppercase;

            display: flex;
            align-items: center;

            a {
                @include basic-a();
                color: var(--color);

                &::before {
                    content: '☞';
                    display: inline-block;
                }

                &::-moz-selection { /* Code for Firefox */
                    color: var(--primary-bg);
                    background: var(--primary-color);
                }
        
                &::selection {
                    color: var(--primary-bg);
                    background: var(--primary-color);
                }
            }
        }
    }
}