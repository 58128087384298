@use "sass:math";

:root {
  --y-header: 0;

  --padding-xl: #{math.div(120px, 16px) * 1rem};
  --padding-l: #{math.div(90px, 16px) * 1rem};
  --padding-m: #{math.div(60px, 16px) * 1rem};
  --padding-s: #{math.div(30px, 16px) * 1rem};
  --padding-xs: #{math.div(15px, 16px) * 1rem};
  --padding-xxs: #{math.div(10px, 16px) * 1rem};
  --padding-xxxs: #{math.div(4px, 16px) * 1rem};

  --header-width: #{rem(180)};
  --header-height: #{rem(105)};
  --header-size: calc(var(--header-width) + var(--padding-xxs));
  
  --scrollbar-height: #{math.div(40px, 16px) * 1rem};

  @media (min-width: $smartphone) {
    --header-height: #{rem(105)};
  }

  @media (max-width: $smartphone) {
    --header-height: #{rem(84)};
  }
}

@media (max-width: $smartphone) {
  :root {}
}
