@use "sass:math";

:root {
    --font-sans: 'AkzidenzGrotesk', sans-serif;
    --font-serif: 'GT-Alpina-Typewriter', serif;

    --font-size-xxl: #{math.div(200px, 16px) * 1rem};
    --font-size-xl: #{math.div(120px, 16px) * 1rem};
    --font-size-l: #{math.div(32px, 16px) * 1rem};
    --font-size-m: #{math.div(24px, 16px) * 1rem};
    --font-size-base: #{math.div(16px, 16px) * 1rem};
    --font-size-s: #{math.div(14px, 16px) * 1rem};
    --font-size-xs: #{math.div(11px, 16px) * 1rem};

    --zoom: 1;
    --line-height-sans: .75;
    --line-height-serif: 1.2;
    --letter-spacing: -0.065em;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: bold;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-bold($line-height:0) {
    font-family: var(--font-sans-bold);
    font-weight: bold;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height:0) {
    font-family: var(--font-sans);
    font-weight: bold;
    font-style: italic;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-serif($line-height:0) {
    font-family: var(--font-serif);
    font-weight: 200;
    font-style: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-serif);  }
}
